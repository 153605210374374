<template>
  <div class="sb_session_bottom-bar_button">
    <div v-if="abbreviation" class="sb_session_bottom-bar_button_abbreviation">
      {{ abbreviation }}
    </div>
    <div v-if="text" class="sb_session_bottom-bar_button_text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    abbreviation: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_session_bottom-bar_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: solid 1px $brand-light-gray;
  height: 100%;
  user-select: none;
  padding: 0 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;

  //
  // ABBREVIATION
  //
  &_abbreviation {
    font-size: 1.4rem;
    color: $brand-primary;
    font-weight: bold;
  }

  //
  // TEXT
  //
  &_text {
    @media screen and (max-height: $small-height-screen) {
      display: none;
    }
  }

  //
  // STATE - HOVER
  //
  &:hover {
    background: $brand-lightest-gray;
    color: $brand-primary;
  }
}
</style>
