<template>
  <div class="sb_session_bottom-bar">
    <div class="sb_session_bottom-bar_container">
      <div class="sb_session_bottom-bar_left">
        <slot name="left"></slot>
      </div>
      <div class="sb_session_bottom-bar_center">
        <slot name="center"></slot>
      </div>
      <div class="sb_session_bottom-bar_right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_session_bottom-bar {
  background: $brand-white;
  padding: 0 1rem;
  box-shadow: 0px -5px 5px rgba($brand-black, 0.1);
  z-index: 3;

  //
  // CONTAINER
  //
  &_container {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 17px 0;

    @media screen and (max-height: $small-height-screen) {
      padding: 5px 0;
    }
  }

  //
  // CENTER
  //
  &_center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: 100%;
    justify-content: center;

    //
    //  LAST BUTTON
    //
    button {
      &:last-child {
        .sb_session_bottom-bar_button {
          border-right: solid 1px $brand-light-gray;
        }
      }
    }
  }
}
</style>
