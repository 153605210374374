<template>
  <div class="sb_cards-session-training-review">
    <modal
      v-model="showFinishModal"
      :closable="false"
      :mask-closable="false"
      footer-hide
    >
      <sb-title text="De oefening is afgerond" size="xsmall" />
      <div class="sb_cards-session-training-review_modal-info">
        {{
          'Doe nog een oefening of ga door naar de volgende fase in de sessie.'
        }}
      </div>
      <div class="sb_cards-session-training-review_modal-buttons">
        <i-button type="primary" @click="handleRepeat">
          Nog een oefening doen
        </i-button>
        <i-button type="primary" @click="handleNext"> Naar toepassen </i-button>
      </div>
    </modal>

    <div class="sb_cards-session-training-review_inner">
      <sb-cards-session-header no-name-summary title="Hoe ging de oefening?" />
      <sb-title
        :text="
          get(getProblemByStudentId(get(students, 0, 'id')), 'type', 'title')
        "
        size="small"
      />

      <sb-spacer height="20" />

      <div
        v-for="student in students"
        :key="student.id"
        class="sb_cards-session-training-review_form"
      >
        <div class="sb_cards-session-training-review_remark">
          Opmerkingen <b>{{ student.fullName }}</b>
        </div>

        <i-input
          v-model="getState().remarks[student.id].value"
          :placeholder="`Eventuele opmerkingen voor ${student.fullName}`"
          :rows="4"
          class="sb_cards-session-training-review_input"
          type="textarea"
        />

        <div class="sb_cards-session-training-review_repeat">
          <checkbox
            v-model="getState().repeats[student.id].value"
            class="sb_cards-session-training-review_checkbox"
          >
            Kaart moet nogmaals geoefend worden.
          </checkbox>
          <checkbox
            :disabled="!canAssignHomework"
            class="sb_cards-session-training-review_checkbox"
            :value="
              isCardAssignedToGroup(student.id) ||
              isCardAssignedPersonally(student.id)
            "
            @on-change="
              toggleAssignedCard(
                student.id,
                !isCardAssignedPersonally(student.id),
              )
            "
          >
            Kaart als huiswerk toewijzen
          </checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SbTitle from '@/components/global/SbTitle.vue';
import SbCardsSessionHeader from './SbCardsSessionHeader.vue';
import gql from 'graphql-tag';
import Vue from 'vue';
import GetStudentAssignedCardsQuery from '@/graphql/queries/GetStudentAssignedCards.gql';
// import SbTooltipInfo from '@/components/SbTooltipInfo.vue';

const CACHE_KEY = 'finishCardExercise';

export default {
  name: 'SbCardsSessionTrainingReview',
  components: { SbCardsSessionHeader, SbTitle },
  inject: ['getParentView', 'sessionState', 'students'],

  props: {
    cardId: { type: String, default: undefined },
  },

  data() {
    return {
      showFinishModal: false,
      assignedCardsMap: {},
    };
  },

  computed: {
    canAssignHomework() {
      return !this.isCardAssignedToGroup(this.cardId);
    },
    studentIds() {
      return this.$route.query.studentIds?.split(',') || [];
    },
  },

  created() {
    const state = this.getState();

    if (!state) {
      this.sessionState[CACHE_KEY] = Vue.observable({
        remarks: this.studentIds.reduce(
          (acc, studentId) => (
            this.$set(acc, studentId, { value: '', dirty: true }), acc
          ),
          {},
        ),
        repeats: this.studentIds.reduce(
          (acc, studentId) => (
            this.$set(acc, studentId, { value: false, dirty: true }), acc
          ),
          {},
        ),
      });
    } else {
      Object.values(state.remarks).forEach(
        (remark) => (remark.dirty = !remark.value),
      );
    }

    Object.keys(this.getState().remarks).forEach((studentId) => {
      this.$watch(
        ['sessionState', CACHE_KEY, 'remarks', studentId].join('.'),
        (remark) => (remark.dirty = true),
        { deep: true },
      );
    });
  },

  mounted() {
    this.getInitialCards();
  },

  methods: {
    getState() {
      return this.sessionState[CACHE_KEY];
    },

    async getInitialCards() {
      const studentIds = this.$route.query.studentIds?.split(',') || [];

      try {
        const dataResults = await Promise.all(
          studentIds.map((studentId) =>
            this.$apollo.query({
              fetchPolicy: 'network-only',
              query: GetStudentAssignedCardsQuery,
              variables: {
                id: studentId,
              },
            }),
          ),
        );

        const resultObject = studentIds.reduce((acc, studentId, index) => {
          acc[studentId] = dataResults[index]?.data?.getUserById;
          return acc;
        }, {});

        this.assignedCardsMap = resultObject;
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      } finally {
        this.$watch('toggle', (value) => {
          value ? undefined : (this.selectedCards = []);
        });
      }
    },

    async toggleAssignedCard(studentId, newValue) {
      try {
        if (this.canAssignHomework) {
          const oldCardIds = this.assignedCardsMap[studentId]
            ?.availableCardsIds;

          const newCardIds = !newValue
            ? oldCardIds.filter((card) => card !== this.cardId)
            : [...oldCardIds, this.cardId];

          await this.$apollo.mutate({
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                id: studentId,
                data: { availableCardsIds: { set: newCardIds } },
              },
            },
            mutation: gql`
              mutation StudentAssignedCards_AssignCards(
                $input: UpdateUserInput!
              ) {
                updateUser(input: $input) {
                  id
                }
              }
            `,
          });

          this.$Message.success({
            content: `Huiswerk ${!newValue ? 'verwijderd' : 'toegevoegd'}`,
            duration: 3,
          });
          this.getInitialCards();
        }
      } catch (error) {
        console.error(error);
        this.$Message.error('Er ging iets mis');
      }
    },

    isCardAssignedPersonally(studentId) {
      return this.assignedCardsMap[studentId]?.availableCardsIds.includes(
        this.cardId,
      );
    },

    isCardAssignedToGroup(studentId) {
      return this.assignedCardsMap[studentId]?.groupAvailableCardsIds.includes(
        this.cardId,
      );
    },

    getTrackByStudentId(studentId) {
      const trackId = this.getParentView?.().studentTrackLookup[studentId];
      return this.students
        ?.find((student) => student.id === studentId)
        ?.tracks.edges.find(({ node }) => node.id === trackId)?.node;
    },

    getProblemByStudentId(studentId) {
      return this.getTrackByStudentId(studentId)?.problems.find(
        (problemEntry) =>
          problemEntry.problem?.id === this.$route.query.problemId,
      );
    },

    handleRepeat() {
      this.studentIds.forEach((id) => {
        this.getState().remarks[id].value = '';
      });

      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, slide: 0 },
      });

      if (this.getParentView) {
        this.getParentView().stepIndex = 2;
      }
    },

    handleNext() {
      if (this.getParentView) {
        this.getParentView().stepIndex++;
      }
    },

    async submitReviews() {
      const sessionIds = this.$route.query.sessionIds?.split(',');
      const exerciseIds = this.$route.query.exerciseIds?.split(',');
      const studentIds = this.$route.query.studentIds?.split(',') || [];
      const remarks = studentIds.map(
        (studentId) => this.getState().remarks[studentId],
      );
      const repeats = studentIds.map(
        (studentId) => this.getState().repeats[studentId],
      );

      if (!sessionIds || !exerciseIds) return;

      this.showFinishModal = true;

      try {
        const results = await Promise.all(
          exerciseIds.map((exerciseId, index) => {
            if (!remarks[index].dirty && !repeats[index].dirty) return;

            if (
              // If it should repeat, don't submit
              repeats[index].value === true
            ) {
              return;
            }

            return this.$apollo.mutate({
              mutation: gql`
                mutation SbCardsSessionTrainingReview_submitReviews(
                  $input: FinishCardExerciseInput!
                ) {
                  finishCardExercise(input: $input) {
                    id
                    remark
                    repeat
                    cardId
                  }
                }
              `,
              variables: {
                input: {
                  id: exerciseId,
                  data: {
                    remark: remarks[index].value,
                    repeat: repeats[index].value,
                  },
                },
              },
            });
          }),
        );

        const ids = results
          .filter((result) => result)
          .map((result) => result.data.finishCardExercise.cardId);

        const combinations = studentIds
          .map((studentId) => {
            return ids.map((id) => {
              return {
                cardId: id,
                studentId,
              };
            });
          })
          .flat();

        const homeworkCombinations = combinations.filter(({ studentId }) => {
          return (
            this.isCardAssignedToGroup(studentId) ||
            this.isCardAssignedPersonally(studentId)
          );
        });

        await Promise.all(
          homeworkCombinations.map(({ cardId, studentId }) =>
            this.$apollo.mutate({
              // This is a hacky way to refresh the card exercises.
              // Currently it is using the finishedAt value to determine if the exercise is finished.
              // If we want to refresh the card exercise we need to set the finishedAt value to null.

              // This is not ideal, and should probably be changed in the future.

              // The reason we do this here is because we only want to show the exercise completed
              // check mark if the exercise is completed within the student dashboard.

              mutation: gql`
                mutation CardsList_RefreshCard(
                  $input: ResetCardExercisesInput!
                ) {
                  resetCardExercises(input: $input) {
                    id
                  }
                }
              `,
              variables: {
                input: {
                  cardId,
                  studentId,
                },
              },
            }),
          ),
        );

        this.$Message.success('Oefening afgerond');
      } catch (error) {
        console.error(error);
        this.$Message.error('Er ging iets mis');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_cards-session-training-review {
  overflow: auto;

  &_inner {
    width: 800px;
    margin: 0 auto;
  }

  &_form {
    margin-bottom: 20px;
  }

  &_checkbox {
    display: inline !important;
    margin-right: 0 !important;
  }

  &_repeat {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    color: $brand-dark-gray;

    > * {
      font-size: 0.9rem;
    }
  }

  &_remark {
    margin-bottom: 10px;
  }

  &_input {
    margin-bottom: 10px;
  }

  &_modal-info {
    margin: 20px 0;
    color: $brand-gray;
  }

  &_modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    & > * {
      flex: 1;

      &:first-child {
        background-color: $brand-env-coach;
        border-color: $brand-env-coach;
      }

      &:last-child {
        background-color: $brand-task-cognition;
        border-color: $brand-task-cognition;
      }
    }
  }
}
</style>
