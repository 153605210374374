<template>
  <sb-cards-session-info-modal v-bind="$attrs" v-on="$listeners">
    <sb-spacer height="30" />
    <sb-cognition-board
      :key="$attrs.value"
      :student-ids="studentIds"
      :track-ids="trackIds"
      style="flex: 1"
    />
  </sb-cards-session-info-modal>
</template>

<script>
import SbCardsSessionInfoModal from './SbCardsSessionInfoModal.vue';
import SbCognitionBoard from '@/components/SbCognitionBoard/SbCognitionBoard.vue';
export default {
  name: 'SbCognitionModal',
  components: { SbCognitionBoard, SbCardsSessionInfoModal },
  props: {
    studentIds: { type: Array, required: true },
    trackIds: { type: Array, required: true },
  },
};
</script>
