<template>
  <div class="sb_cards-session-focus">
    <modal
      v-model="problemInfoModal"
      footer-hide
      :title="get(activeProblemInfo, 'title')"
      :width="800"
      @close="activeProblemInfo = undefined"
    >
      <div v-html="get(activeProblemInfo, 'description')" />
    </modal>

    <sb-loading
      v-if="
        getParentView &&
        get(getParentView(), '$apollo', 'queries', 'getUserById', 'loading')
      "
    />

    <template v-if="students.length > 0">
      <div class="sb_cards-session-focus_inner">
        <!-- title & intro -->
        <sb-cards-session-header :title="title" :description="description">
          <div class="tag-container">
            <div v-for="tag in tags" class="tag">
              {{ tag }}
            </div>
          </div>
        </sb-cards-session-header>

        <!-- TRACK PROBLEMS -->
        <!-- task picker -->
        <template v-if="accumulatedProblemsAmount > 0 && students.length > 0">
          <sb-list-head style="grid-template-columns: 1fr 250px 300px">
            <sb-list-head-col> Taken </sb-list-head-col>
            <sb-list-head-col> Aantal x geoefend </sb-list-head-col>
            <sb-list-head-col> Technieken </sb-list-head-col>
          </sb-list-head>

          <sb-list-row
            v-for="(entry, key, index) in accumulatedProblems"
            :key="key"
            class="sb_cards-session-focus_problem-type-row"
            :style="{
              gridTemplateColumns: '50px 40px 1fr 250px 300px',
              zIndex: Object.keys(accumulatedProblems).length - index,
              position: 'relative',
            }"
            :data-id="key"
            @click.native="handleListRowClick"
          >
            <sb-list-row-col
              style="height: 100%; padding: 0"
              @click.native.stop="
                showProblemInfoModal(get(entry, 'problem', 'problem'))
              "
            >
              <div class="info">
                <sb-icon icon-id="icon_info" />
              </div>
            </sb-list-row-col>

            <sb-list-row-col>
              <radio
                size="large"
                :value="problemId === key"
                @input="$event ? (problemId = key) : false"
              />
            </sb-list-row-col>

            <sb-list-row-col>
              <div>{{ get(entry, 'problem', 'problem', 'title') }}</div>
            </sb-list-row-col>

            <sb-list-row-col>
              {{ entry.sessionsAmount }}
              <span style="width: 30px; display: inline-block" />
              {{
                entry.sessionsAmount && entry.lastSessionDate
                  ? new Date(entry.lastSessionDate).toLocaleDateString()
                  : '-'
              }}
            </sb-list-row-col>

            <sb-list-row-col>
              <sb-problems-input-techniques
                :items="linkedTechniquesFromProblem(entry.problem)"
                @click-technique="handleTechniqueClick"
              />
            </sb-list-row-col>
          </sb-list-row>
        </template>

        <!-- if the student has an active track, but without selected problems -->
        <template v-else>
          <div class="sb_cards-session-focus_inner">
            <sb-call-out>
              <template slot="left"> ℹ️ </template>
              <template slot="right">
                <p>
                  {{
                    studentTracksWithoutProblems.length === 1
                      ? 'Deze leerling heeft een actief traject zonder gedefinieerde problematieken.'
                      : `De volgende leerlingen hebben een actief traject zonder problematieken: ${studentTracksWithoutProblems
                          .map(({ student }) => student.fullName)
                          .join(', ')}.`
                  }}
                </p>
                <p>
                  Kies een focuspunt voor deze sessie uit onderstaande taken.
                </p>
              </template>
            </sb-call-out>
          </div>
        </template>

        <sb-spacer height="40" />

        <!-- COMMON PROBLEMS -->

        <template v-if="showCommonProblems">
          <div
            v-if="studentTracks.length !== studentTracksWithoutProblems.length"
            class="sb_common-problems-title"
          >
            Overige focuspunten
          </div>

          <sb-list-head style="grid-template-columns: 1fr 250px 300px">
            <sb-list-head-col> Taken </sb-list-head-col>
            <sb-list-head-col> Aantal x geoefend </sb-list-head-col>
            <sb-list-head-col> Technieken </sb-list-head-col>
          </sb-list-head>

          <sb-list-row
            v-for="(problem, key, index) in commonProblems"
            :key="key"
            class="sb_cards-session-focus_problem-type-row"
            :style="{
              gridTemplateColumns: '50px 40px 1fr 250px 300px',
              zIndex: Object.keys(commonProblems).length - index,
              position: 'relative',
            }"
            :data-id="key"
            @click.native="handleListRowClick"
          >
            <sb-list-row-col
              style="height: 100%; padding: 0"
              @click.native.stop="showProblemInfoModal(problem)"
            >
              <div class="info">
                <sb-icon icon-id="icon_info" />
              </div>
            </sb-list-row-col>

            <sb-list-row-col>
              <radio
                size="large"
                :value="problemId === key"
                @input="$event ? (problemId = key) : false"
              />
            </sb-list-row-col>

            <sb-list-row-col>
              <div>{{ problem.title }}</div>
            </sb-list-row-col>

            <sb-list-row-col>
              {{ problem.sessionsAmount }}
              <span style="width: 30px; display: inline-block" />
              {{
                problem.sessionsAmount && problem.lastSessionDate
                  ? new Date(problem.lastSessionDate).toLocaleDateString()
                  : ''
              }}
            </sb-list-row-col>

            <sb-list-row-col>
              <sb-problems-input-techniques
                :items="problem.linkedTechniques || []"
                @click-technique="handleTechniqueClick"
              />
            </sb-list-row-col>
          </sb-list-row>
        </template>

        <div v-else style="display: flex; justify-content: center">
          <i-button size="large" type="text" @click="showCommonProblems = true">
            Bekijk overige focuspunten
          </i-button>
        </div>

        <sb-spacer height="60" />
      </div>
    </template>
  </div>
</template>

<script>
import { changeLoggerMixin } from '@/mixins/changeLogger';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import SbListHead from '@/components/SbListHead.vue';
import SbListHeadCol from '@/components/SbListHeadCol.vue';
import SbListRow from '@/components/SbListRow.vue';
import SbListRowCol from '@/components/SbListRowCol.vue';
import SbLoading from '@/components/SbLoading.vue';
import SbCallOut from '@/components/SbCallOut.vue';
import SbCardsSessionHeader from './SbCardsSessionHeader.vue';
import SbProblemsInputTechniques from '@/components/SbProblemsInputTechniques.vue';
import gql from 'graphql-tag';

import { problemsMixin } from '@/mixins/problemsMixin';

export default {
  name: 'SbCardsSessionFocus',
  components: {
    SbListHead,
    SbListHeadCol,
    SbListRow,
    SbListRowCol,
    SbCardsSessionHeader,
    SbLoading,
    SbCallOut,
    SbProblemsInputTechniques,
  },

  inject: ['getParentView'],

  mixins: [
    nonReactiveMembersMixin(() => ({
      title: 'Waar gaan we mee aan de slag?',
      description: 'Kies hieronder het focuspunt voor deze sessie.',
    })),
    changeLoggerMixin(['problemId']),
    problemsMixin,
  ],

  data() {
    return {
      cardLevels: [],
      problemId: this.$route.query.problemId,
      showCommonProblems: false,
      activeProblemInfo: undefined,
      problemInfoModal: false,
    };
  },

  computed: {
    students() {
      return this.getParentView?.().students;
    },

    tags() {
      return Array.from(new Set(this.cardLevels));
    },

    accumulatedProblems() {
      return this.students?.reduce((acc, student) => {
        let lastSessionDate;
        const tracks = student.tracks.edges
          .map(({ node }) => node)
          .filter((track) => track.active);

        const problems = tracks.map((track) => track.problems).flat();

        problems.forEach((problem) => {
          const sessionsAmount = tracks.reduce((acc, track) => {
            track.sessions?.edges
              .map(({ node }) => node)
              .filter((session) => {
                return (
                  !!session.problemFocus &&
                  !!problem.problem &&
                  session.problemFocus.id === problem.problem.id
                );
              })
              .forEach((session) => {
                session.cards
                  ?.filter((exercise) => !!exercise.finishedAt)
                  .forEach((exercise) => {
                    acc++;
                    if (
                      !lastSessionDate ||
                      exercise.finishedAt > lastSessionDate
                    ) {
                      lastSessionDate = exercise.finishedAt;
                    }
                  });
              });

            return acc;
          }, 0);

          if (!problem.problem) return;

          const entry =
            acc[problem.problem.id] ||
            (acc[problem.problem.id] = {
              relatedStudentIds: [],
              sessionsAmount,
              lastSessionDate,
              problem,
            });

          entry.relatedStudentIds.push(student.id);
        });

        return acc;
      }, {});
    },

    accumulatedProblemsAmount() {
      return Object.values(this.accumulatedProblems).length;
    },

    commonProblems() {
      return this.students?.reduce((acc, student) => {
        let lastSessionDate;
        const tracks = student.tracks.edges
          .map(({ node }) => node)
          .filter((track) => track.active);

        this.problems?.forEach((problem) => {
          if (Object.keys(this.accumulatedProblems).includes(problem.id)) {
            return;
          }

          const sessionsAmount = tracks.reduce((acc, track) => {
            track.sessions?.edges
              .map(({ node }) => node)
              .filter((session) => {
                return (
                  !!session.problemFocus &&
                  !!problem &&
                  session.problemFocus.id === problem.id
                );
              })
              .forEach((session) => {
                session.cards
                  ?.filter((exercise) => !!exercise.finishedAt)
                  .forEach((exercise) => {
                    acc++;
                    if (
                      !lastSessionDate ||
                      exercise.finishedAt > lastSessionDate
                    ) {
                      lastSessionDate = exercise.finishedAt;
                    }
                  });
              });

            return acc;
          }, 0);

          acc[problem.id] = { ...problem, sessionsAmount };
          return acc;
        });

        return acc;
      }, {});
    },

    commonProblemsAmount() {
      return Object.keys(this.commonProblems).length;
    },

    studentTracks() {
      return (
        this.students?.reduce((acc, student) => {
          const track = student.tracks.edges
            .map(({ node }) => node)
            .filter((track) => track.active)[0];

          acc.push({ student, track });

          return acc;
        }, []) ?? []
      );
    },

    studentTracksWithoutProblems() {
      return (
        this.students?.reduce((acc, student) => {
          const track = student.tracks.edges
            .map(({ node }) => node)
            .filter((track) => track.active)[0];

          const problemsAmount = track.problems.length;

          if (problemsAmount < 1) {
            acc.push({
              student,
              track,
            });
          }

          return acc;
        }, []) ?? []
      );
    },
  },

  watch: {
    studentTracksWithoutProblems: {
      handler(value, prevValue) {
        if (value?.length !== prevValue?.length && (value?.length || 0) > 0) {
          this.showCommonProblems = true;
        }
      },
      immediate: true,
    },

    problemId(value, prevValue) {
      const current = this.$route.query.problemId;
      if (value === prevValue || current === value) return;

      this.$router
        .replace({
          path: this.$route.path,
          query: { ...this.$route.query, problemId: value },
        })
        .catch(() => null);
    },
  },

  mounted() {
    this.$apollo.queries.problems.refresh();
    this.getParentView?.().$apollo.queries.getUserById.refresh();
  },

  methods: {
    handleTechniqueClick(technique) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { ...this.$route.query, techniqueId: technique.id },
        })
        .catch(() => null);
    },

    showProblemInfoModal(problem) {
      this.activeProblemInfo = problem;
      this.problemInfoModal = true;
    },

    handleListRowClick(event) {
      this.problemId = event.currentTarget.dataset.id;
    },

    linkedTechniquesFromProblem(problem) {
      return problem.problem.linkedTechniques;
    },
  },

  apollo: {
    studentReadingLevels: {
      skip() {
        return this.$user.role !== 'COACH';
      },

      query() {
        return gql`
          query StudentReadingLevels(
            $first: Int
            $after: String
            $last: Int
            $before: String
            $filter: TracksFilter
            $orderBy: TracksOrderBy
          ) {
            tracks(
              first: $first
              after: $after
              last: $last
              before: $before
              filter: $filter
              orderBy: $orderBy
            ) {
              edges {
                node {
                  id
                  readingLevels
                  active
                }
              }
            }
          }
        `;
      },
      variables() {
        const studentIds = this.$route.query.studentIds?.split(',') ?? [];
        return {
          first: 10,
          filter: {
            active: true,
            users: [
              {
                userId: {
                  in: studentIds,
                },
              },
            ],
          },
          orderBy: {
            createdAt: 'DESC',
          },
        };
      },

      update(data) {
        this.cardLevels = data.tracks.edges.reduce((acc, { node }) => {
          if (node.active) {
            acc.push(...node.readingLevels);
          }
          return acc;
        }, []);
      },
    },
  },
};
</script>

<style lang="scss">
.sb_cards-session-focus {
  overflow: auto;

  &_inner {
    padding: 0 2rem;
  }

  &_problem-type-row {
    cursor: pointer;
    font-size: 700;
    min-height: 70px;
  }
}

.sb_common-problems-title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
}

.info {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 1.2rem;

  svg {
    margin: 0 !important;
  }

  @media (hover: none) {
    color: $brand-primary;
  }

  &:hover {
    cursor: pointer;
    color: $brand-primary;
    transition-duration: 0s;

    svg {
      color: currentColor;
    }
  }

  svg {
    margin: 0 0.5rem;
    transform: translateY(-2px);
    color: $brand-gray;
  }
}

.tag-container {
  display: flex;
  gap: 5px;
  margin-top: 20px;
  font-size: 0.8rem;

  .tag {
    padding: 3px 20px;
    color: white;
    background-color: $brand-primary;
    border-radius: 500px;
  }
}
</style>
