<template>
  <div class="sb_view_session" :class="{ fullscreen: fullscreen.toggled }">
    <!-- modals -->

    <sb-techniques-modal v-model="showTechniquesModal" />

    <sb-session-modal
      v-model="showSessionModal"
      :students="students"
      :problem-id="$route.query.problemId"
      :card-id="$route.query.cardId"
    />

    <sb-cognition-modal
      v-model="showCognitionModal"
      :student-ids="studentIds"
      :track-ids="trackIds"
    />

    <sb-diagnose-modal v-model="showDiagnoseModal" :student-ids="studentIds" />

    <!-- steps indicator -->
    <sb-session-steps-indicator
      v-if="!fullscreen.toggled"
      :steps="stepsIndicator"
      :current-step="indicatorStepProxy"
      @click-step="handleStepClick"
    />

    <!-- views -->
    <sb-cards-session-focus v-if="isStep(0)" ref="focus" />
    <sb-cards-session-training-select v-if="isStep(1)" ref="trainingSelect" />
    <sb-cards-session-training v-if="isStep(2)" ref="training" />
    <sb-cards-session-training-review
      v-if="isStep(3)"
      ref="review"
      :card-id="$route.query.cardId"
    />
    <sb-cards-session-application v-if="isStep(4)" ref="application" />
    <sb-cards-session-completion v-if="isStep(5)" ref="completion" />

    <!-- navigation -->
    <sb-session-bottom-bar v-if="!fullscreen.toggled">
      <template #left>
        <button
          v-if="previousButtonIsRendered"
          :disabled="previousButtonIsDisabled"
          @click="handlePreviousClick"
        >
          <sb-button
            :disabled="previousButtonIsDisabled"
            icon-animation-direction="left"
            naked
            collapsed-left
          >
            <sb-icon icon-id="icon_arrow-left" />
            {{ previousButtonLabel }}
          </sb-button>
        </button>
      </template>

      <template #center>
        <button @click="showSessionModal = true">
          <sb-session-bottom-bar-button abbreviation="Se" text="Sessie" />
        </button>
        <button @click="showDiagnoseModal = true">
          <sb-session-bottom-bar-button abbreviation="Di" text="Diagnose" />
        </button>
        <button @click="showCognitionModal = true">
          <sb-session-bottom-bar-button abbreviation="Co" text="Cognitie" />
        </button>
        <button @click="showTechniquesModal = true">
          <sb-session-bottom-bar-button abbreviation="Te" text="Technieken" />
        </button>
      </template>

      <template #right>
        <button
          v-if="nextButtonIsRendered"
          id="cards-session-next"
          :disabled="nextButtonIsDisabled"
          @click="handleNextClick"
        >
          <sb-button
            :disabled="nextButtonIsDisabled"
            icon-animation-direction="right"
          >
            {{ nextButtonLabel }}
            <sb-icon icon-id="icon_arrow-right" />
          </sb-button>
        </button>
      </template>
    </sb-session-bottom-bar>
  </div>
</template>

<script>
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import gql from 'graphql-tag';
import SbCardsSessionApplication from './SbCardsSessionApplication.vue';
import SbCardsSessionCompletion from './SbCardsSessionCompletion.vue';
import SbCardsSessionFocus from './SbCardsSessionFocus.vue';
import SbCardsSessionTraining from './SbCardsSessionTraining.vue';
import SbCardsSessionTrainingReview from './SbCardsSessionTrainingReview.vue';
import SbCardsSessionTrainingSelect from './SbCardsSessionTrainingSelect.vue';
import SbDiagnoseModal from './SbDiagnoseModal.vue';
import SbSessionModal from './SbSessionModal.vue';
import SbSessionBottomBar from '@/components/session/SbSessionBottomBar';
import SbSessionBottomBarButton from '@/components/session/SbSessionBottomBarButton';
import SbSessionStepsIndicator from '@/components/session/SbSessionStepsIndicator';
import SbTechniquesModal from './SbTechniquesModal.vue';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import SbCognitionModal from './SbCognitionModal.vue';
import Vue from 'vue';
import { objectOmit } from '@/lib/object-omit';
import GetSessionUserByCoachId from '@/graphql/queries/GetSessionUserByCoachId.gql';

export default {
  name: 'SessionCards',

  components: {
    SbCardsSessionApplication,
    SbCardsSessionCompletion,
    SbCardsSessionFocus,
    SbCardsSessionTraining,
    SbCardsSessionTrainingReview,
    SbCardsSessionTrainingSelect,
    SbSessionModal,
    SbDiagnoseModal,
    SbSessionBottomBar,
    SbSessionBottomBarButton,
    SbSessionStepsIndicator,
    SbTechniquesModal,
    SbCognitionModal,
  },

  mixins: [
    nonReactiveMembersMixin(() => ({ ROUTE_ORDER })),
    changeLoggerMixin(['students']),
  ],

  data() {
    return {
      showFinishCardModal: false,
      showSessionModal: false,
      showDiagnoseModal: false,
      showCognitionModal: false,
      showTechniquesModal: false,
      students: [],
      fullscreen: { toggled: false },
      cardPracticeAmountLookup: undefined,

      stepIndex: (() => {
        const stepQuery = this.$route.query.step;
        const number = parseInt(stepQuery);
        return isNaN(number) ? 0 : number;
      })(),

      sessionState: {},
    };
  },

  provide() {
    const self = this;
    return {
      stepIndex: this.stepIndex,
      getParentView: () => self,
      students: this.students,
      fullscreen: this.fullscreen,
      sessionState: Vue.observable({}),
      _sessionState: () => self.sessionState,
    };
  },

  computed: {
    stepsIndicator() {
      const step = parseInt(this.$route.query.step || '0');
      const sessionIds = this.$route.query.sessionIds?.split(',') || [];

      return ROUTE_ORDER.map((route, index) => ({
        ...route,
        enabled: (() => {
          const enabled =
            index === 0 ||
            ((index === 1 || index === 2) && !!sessionIds.length) ||
            (index === 5 && step >= 5) ||
            (index === 6 && step >= 5) ||
            (index === 7 && step >= 5);
          return enabled;
        })(),
      }));
    },

    studentIds() {
      return this.$route.query.studentIds?.split(',') || [];
    },

    trackIds() {
      return this.$route.query.trackIds?.split(',') || [];
    },

    nextButtonIsRendered() {
      return true;
    },

    nextButtonIsDisabled() {
      console.log('next disabled?', this.stepIndex);
      switch (this.stepIndex) {
        case 0:
          return !this.$refs.focus.problemId;

        case 1:
          return !this.$route.query.cardId;

        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return false;

        default:
          return true;
      }
    },

    previousButtonIsRendered() {
      switch (this.stepIndex) {
        default:
          return true;
      }
    },

    previousButtonIsDisabled() {
      return false;
    },

    nextButtonLabel() {
      switch (this.stepIndex) {
        case 2:
          return this.$refs.training.hasNextSlide
            ? 'Volgende dia'
            : 'Kaart afronden';

        case 3:
          return 'Oefening afronden';

        case 5:
          return 'Sessie afronden';

        default:
          return 'Volgende';
      }
    },

    previousButtonLabel() {
      return this.stepIndex === 2
        ? this.$refs.training.hasPreviousSlide
          ? 'Vorige dia'
          : 'Kaart kiezen'
        : 'Vorige';
    },

    studentTrackLookup() {
      const result =
        this.students?.reduce((acc, student) => {
          if (!student.tracks.edges.length) return acc;
          acc[student.id] = student.tracks.edges[0].node.id;
          return acc;
        }, {}) || {};

      return result;
    },

    indicatorStepProxy() {
      switch (this.stepIndex) {
        case 2:
        case 3:
          return 1;

        case 4:
        case 5:
        case 6:
          return this.stepIndex - 2;

        default:
          return this.stepIndex;
      }
    },
  },

  watch: {
    '$route.query.techniqueId'(techniqueId) {
      const sessionIds = this.$route.query.sessionIds?.split(',');

      if (!sessionIds?.length) return;

      const getMutation = (sessionId) => `
        ${sessionId}: updateSession(input: { id: "${sessionId}", data: { appliedTechniqueId: ${techniqueId} } }) {
          id
          appliedTechniqueId
        }
      `;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ApplyTechniqueToSessions {
              ${sessionIds.map((id) => getMutation(id)).join('\n')}
            }
          `,
        })
        .then((result) => {
          console.log('applied technique to sessions', result);
        })
        .catch((error) => {
          console.error('could not apply technique id to sessions', { error });
        });
    },

    stepIndex: {
      handler(value, prevValue) {
        if (value == prevValue || this.$route.query.step == value) return;
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, step: value },
        });
      },
    },
  },

  methods: {
    handleStepClick(step) {
      const { name } = step;

      switch (name) {
        case 'SessionFocus':
          return (this.stepIndex = 0);
        case 'SessionTraining':
          return (this.stepIndex = 1);
        case 'SessionPractice':
          return (this.stepIndex = 4);
        case 'SessionFinish':
          return (this.stepIndex = 5);
      }
    },

    handleNextClick() {
      const refs = this.$refs;
      const stepIndex = this.stepIndex;

      if (stepIndex === 0) this.createSession();
      if (stepIndex === 1) refs.trainingSelect.submitCard();
      if (stepIndex === 2 && this.nextCardSlide()) return;
      if (stepIndex === 3) return refs.review.submitReviews();
      if (stepIndex === 4) refs.application.submitAwareness();
      if (stepIndex === 5) return refs.completion.submitEntries();

      this.stepIndex = Math.min(
        this.ROUTE_ORDER.length - 1,
        this.stepIndex + 1,
      );
    },

    handlePreviousClick() {
      const stepIndex = this.stepIndex;
      if (stepIndex === 0) return this.$router.back();

      if (stepIndex === 2) {
        if (this.previousCardSlide()) return;
      }

      this.stepIndex = Math.max(0, this.stepIndex - 1);
    },

    nextCardSlide() {
      const view = this.$refs.training;

      if (view.hasNextSlide) {
        view.nextSlide();
        return true;
      }

      return false;
    },

    previousCardSlide() {
      const view = this.$refs.training;

      if (view.hasPreviousSlide) {
        view.previousSlide();
        return true;
      }

      this.$router.replace({
        path: this.$route.path,
        query: objectOmit(this.$route.query, 'cardId'),
      });

      return false;
    },

    isStep(index) {
      return this.stepIndex === index;
    },

    getTrackByStudentId(studentId) {
      const trackId = this.studentTrackLookup[studentId];
      return this.students
        ?.find((student) => student.id === studentId)
        ?.tracks.edges.find(({ node }) => node.id === trackId)?.node;
    },

    async createSession() {
      try {
        if (!this.$refs.focus.problemId) {
          throw new Error('problemId undefined');
        }

        const results = await Promise.all(
          this.students.map((student) =>
            this.$apollo.mutate({
              mutation: gql`
                mutation CreateSession($input: CreateSessionInput!) {
                  createSession(input: $input) {
                    id
                  }
                }
              `,
              variables: {
                input: {
                  appliedTechniqueId: this.$route.query.techniqueId,
                  problemFocusId: this.$refs.focus.problemId,
                  trackId: this.studentTrackLookup[student.id],
                  practice: {
                    type:
                      this.students.length > 1
                        ? 'Groepsessie'
                        : 'Individuele sessie',
                  },
                  cards: [],
                },
              },
            }),
          ),
        );

        const sessionIds = results.map(
          (result) => result.data.createSession.id,
        );

        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, sessionIds: sessionIds.join(',') },
        });

        this.$Message.success({ content: 'Sessie aangemaakt' });
      } catch (error) {
        console.error(error);
        this.$Message.error({ content: 'Er ging iets mis' });
        this.stepIndex--;
      }
    },
  },

  apollo: {
    getUserById: {
      fetchPolicy: 'cache-and-network',

      variables() {
        return {
          id: this.$user.id,
          usersFilter: { id: { in: this.studentIds } },
          tracksFilter: { active: true, id: { in: this.trackIds } },
        };
      },

      query: GetSessionUserByCoachId,

      update(data) {
        const newStudents = data.getUserById.coachingStudents.edges.map(
          ({ node }) => node,
        );
        // update array in this way because vue loses reactivity via provider if we just reassign this.students...

        // clear original array
        this.students.splice(0, this.students.length);

        // use `push` to append new entries because `<array>.push` is reactive
        // map through studentIds so api result is always in same order as query params for consistency
        this.students.push(
          ...this.studentIds.map((studentId) =>
            newStudents.find((student) => student.id === studentId),
          ),
        );
      },
    },
  },
};

const ROUTE_ORDER = [
  {
    title: 'Focus',
    name: 'SessionFocus',
    task: 'diagnose',
    showInIndicator: true,
  },
  {
    title: 'Trainen',
    name: 'SessionSelectTraining',
    task: 'practise',
    showInIndicator: false,
  },
  {
    title: 'Trainen',
    name: 'SessionTraining',
    task: 'practise',
    showInIndicator: true,
  },
  {
    title: 'Trainen',
    name: 'SessionReviewTraining',
    task: 'practise',
    showInIndicator: false,
  },
  // {
  //   title: 'Transferbewustzijn',
  //   name: 'TransferAwareness',
  //   task: 'cognition',
  //   showInIndicator: true,
  // },
  {
    title: 'Toepassen',
    name: 'SessionPractice',
    task: 'apply',
    showInIndicator: true,
  },
  {
    title: 'Afronden',
    name: 'SessionFinish',
    task: 'cognition',
    showInIndicator: true,
  },
];

const STEPS = {
  SessionFocus: 0,
  SessionCognition: 1,
  SessionSelectTraining: 2,
  SessionTraining: 2,
  TransferAwareness: 3,
  SessionPractice: 4,
  SessionFinish: 5,
};
</script>

<style lang="scss">
.sb_view_session {
  position: absolute;
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;

  &.fullscreen {
    grid-template-rows: 1fr;
  }

  //
  // ROUTER VIEW
  //
  &_router-view {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.sb_modal-methods_layout {
  max-width: rem-calc(1000);
  display: grid;
  grid-template-columns: 280px 1fr;

  //
  //  MENU
  //
  &_menu {
  }

  //
  //  CONTENT
  //
  &_content {
  }
}
</style>
