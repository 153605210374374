<template>
  <div class="sb_cards-session_training-select">
    <div class="sb_cards-session_training-select_inner">
      <sb-cards-session-header title="Selecteer een oefenkaart" />

      <sb-cards-suggestions
        :cards="cards"
        @card-select="handleSuggestionSelect"
      />

      <sb-cards-list
        ref="cardsList"
        :key="
          [enableReadingComprehensionPreFilter, cardLevels.join(',')].join('-')
        "
        v-model="cardId"
        :default-filters="{
          cardLevel: cardLevels,
          readingComprehension: enableReadingComprehensionPreFilter,
        }"
        :student-ids="studentIds"
        :show-exercise-status-enabled="true"
        with-linked-problems
        @cards-change="cards = $event"
        @practice-amounts-change="practiceAmountLookup = $event"
        @filters-change="cardFilters = $event"
      />
      <sb-spacer height="40" />
    </div>
  </div>
</template>

<script>
import SbCardsList from '@/components/SbCardsList.vue';
import { readingLevelMixin } from '@/lib/reading-level';
import { changeLoggerMixin } from '@/mixins/changeLogger';
import gql from 'graphql-tag';
import SbCardsSessionHeader from './SbCardsSessionHeader.vue';
import SbCardsSuggestions from './SbCardsSuggestions.vue';

export default {
  name: 'SbCardsSessionTrainingSelect',

  components: {
    SbCardsSessionHeader,
    SbCardsList,
    SbCardsSuggestions,
  },

  mixins: [readingLevelMixin, changeLoggerMixin([])],

  data() {
    return {
      cardId: this.$route.query.cardId
        ? Number(this.$route.query.cardId)
        : undefined,
      cards: [],
      practiceAmountLookup: undefined,
      cardFilters: undefined,
    };
  },

  computed: {
    studentIds() {
      return this.$route.query.studentIds?.split(',') ?? [];
    },

    enableReadingComprehensionPreFilter() {
      const result = this.students
        ?.map((student) =>
          student.tracks.edges
            .map(({ node }) => node.isReadingComprehension)
            .every(Boolean),
        )
        .every(Boolean);

      return result;
    },

    cardLevels() {
      return Array.from(
        new Set(
          this.students
            ?.map((student) =>
              student.tracks.edges.map(({ node }) => node.readingLevels),
            )
            .flat() ?? [],
        ),
      );
    },
  },

  watch: {
    practiceAmountLookup(value) {
      if (this.getParentView) {
        this.getParentView().cardPracticeAmountLookup = value;
      }
    },

    cardId: {
      handler(value, prevValue) {
        if (!value == prevValue || this.$route.query.cardId == value) return;
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, cardId: value },
        });
      },
      immediate: true,
    },
  },

  methods: {
    handleSuggestionSelect(cardId) {
      this.cardId = cardId;
      this.$nextTick(() =>
        document.getElementById('cards-session-next').click(),
      );
    },

    handleListRowClick(event) {
      this.cardId = Number(event.currentTarget.dataset.id);
    },

    async submitCard() {
      const cardId = this.cardId;
      const sessionIds = this.$route.query.sessionIds?.split(',');
      const trackIds = this.$route.query.trackIds?.split(',');
      let card;

      try {
        const { data } = await this.$apollo.query({
          query: gql`query CardsSessionTrainingSelect_GetCardReadingLevel {
            getCardById(id: "${cardId}") {
              id
              level
              title
            }
          }`,
        });

        card = data.getCardById;

        if (!card) throw new Error(`Card ${cardId} not found`);
        if (!card.level) throw new Error(`Card level is ${card.level}`);
      } catch {
        this.$showGenericError(
          'Kaart niet gevonden',
          `We konden kaart ${cardId} niet vinden.`,
        );
      }

      if (!sessionIds || !trackIds || sessionIds.length !== trackIds.length) {
        return;
      }

      try {
        const exerciseIds = await Promise.all(
          sessionIds.map(async (sessionId) => {
            const readingLevel = card.level;
            const { data } = await this.$apollo.mutate({
              fetchPolicy: 'no-cache',
              mutation: gql`
                mutation CreateCardExercise($input: CreateCardExerciseInput!) {
                  createCardExercise(input: $input) {
                    id
                  }
                }
              `,
              variables: {
                input: { cardId, sessionId, readingLevel },
              },
            });

            return data.createCardExercise.id;
          }),
        );

        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            exerciseIds: exerciseIds.join(','),
          },
        });
      } catch (error) {
        console.error(error);
        this.$showGenericError(
          undefined,
          `Oefening voor kaart ${card.title} kon niet aangemaakt worden.`,
        );
      }
    },
  },
};
</script>

<style lang="scss">
.ivu-btn-text {
  &:hover {
    color: $brand-black !important;
  }
}
</style>

<style lang="scss" scoped>
.sb_suggestion-notice {
  margin-bottom: 0em !important;
  color: $brand-dark-gray;
}

.sb_suggestion-card {
  display: block;
  margin-block: 1rem;
}

.sb_cards-session_training-select {
  overflow: auto;

  &_inner {
    padding: 0 2rem;
  }

  &_card-row {
    min-height: 70px;
    cursor: pointer;
  }

  &_card-number {
    display: inline-block;
    padding: 7px 5px 5px;
    background: $brand-primary;
    border-radius: $default-border-radius;
    color: $brand-white;
  }
}

.sb_suggestions-container {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  gap: 1.5rem;
}

.sb_suggestion-card {
  width: 300px;
  padding: 1rem;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid $brand-light-gray;
  box-sizing: border-box;
  box-shadow: 0 0 8px rgba($brand-primary-darkest, 0.1);
  cursor: pointer;
  transition: 0.3s background-color;

  &:hover {
    background: $brand-primary-lightest;
    transition-duration: 0s;
  }

  &_number {
    font-size: 0.9rem;
    font-weight: 600;
  }

  &_title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 800;
  }

  &_level-type {
    margin: 0.5rem 0;
    span {
      margin-right: 0.5rem;
    }
  }

  &_methods {
    display: flex;
    gap: 0.5rem;
    overflow: auto;
    white-space: nowrap;
    margin: 0 -1rem;
    padding: 0 1rem;

    // hide scroll bar but allow scrolling
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_method {
    display: inline-block;
    font-size: 0.9rem;
    background: rgba($brand-primary, 0.5);
    color: white;
    padding: 2px 8px;
    border-radius: 1em;
  }
}
</style>
