<template>
  <div class="sb_cards-session_header">
    <slot />
    <sb-spacer v-if="!noSpacer" height="60" />
    <div v-if="!noNameSummary" class="sb_cards-session_header_pre-title">
      {{ namesSummary }}
    </div>
    <div class="sb_cards-session_header_title">
      {{ title }}
    </div>
    <p class="sb_cards-session_header_description">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SbCardsSessionHeader',

  inject: ['getParentView'],

  props: {
    title: { type: String, required: true },
    description: { type: String, default: '' },
    noSpacer: { type: Boolean, required: false },
    noNameSummary: { type: Boolean, required: false },
  },

  computed: {
    students() {
      return this.getParentView?.().students;
    },

    namesSummary() {
      const names = this.students?.map((student) => student.fullName) || [];
      if (names.length === 1) return names[0];
      return [
        names.slice(0, names.length - 1).join(', '),
        ...names.slice(-1),
      ].join(' en ');
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_cards-session_header {
  &_pre-title {
    font-weight: 700;
    font-size: 24px;
  }

  &_title {
    font-weight: 700;
    font-size: 36px;
    margin: 10px 0;
  }

  &_description {
    color: $brand-dark-gray;
    margin: 10px 0;
  }
}
</style>
