<template>
  <sb-cards-session-info-modal v-bind="$attrs" v-on="$listeners">
    <sb-title> Sessie </sb-title>

    <p style="margin: 0">
      In dit venster vind je informatie over de huidige sessie.
    </p>

    <tabs :animated="false" class="sb_session-modal_tabs">
      <tab-pane label="Kaart">
        <template v-if="card">
          <div class="row">
            <p>#{{ get(card, 'number') }}</p>
            <sb-title size="xsmall">
              {{ get(card, 'title') }}
            </sb-title>

            <p>
              <b style="margin-right: 0.5em">{{ get(card, 'level') }}</b>
              <span>{{ get(card, 'type') }}</span>
            </p>
          </div>

          <div class="row">
            <p
              v-if="
                get(
                  getParentView().cardPracticeAmountLookup,
                  get(card, 'id'),
                  'count',
                )
              "
            >
              Aantal keer geoefend:
              {{
                get(
                  getParentView().cardPracticeAmountLookup,
                  get(card, 'id'),
                  'count',
                )
              }}
            </p>

            <p v-else>Nog niet geoefend</p>
          </div>

          <div class="row">
            <sb-title size="xxsmall" style="margin-top: 2rem">
              Methodes
            </sb-title>
            <div>
              <span
                v-for="method in get(card, 'methodologies')"
                :key="method"
                class="pill"
              >
                {{ method }}
              </span>
            </div>
          </div>
        </template>

        <div v-else class="row"><p>Nog geen kaart gekozen</p></div>
      </tab-pane>

      <tab-pane label="Problematiek">
        <template v-if="problem">
          <div class="row">
            <sb-title size="xsmall"> {{ get(problem, 'title') }} </sb-title>
            <div v-html="get(problem, 'description')" />
          </div>

          <div class="row">
            <sb-title size="xxsmall"> Probleemtypes </sb-title>

            <span
              v-for="type in get(problem, 'types')"
              :key="type"
              class="pill"
            >
              {{ getProblemTypeLabel(type, true) }}
            </span>
          </div>
          <div class="row">
            <sb-title size="xxsmall"> Technieken </sb-title>

            <span
              v-for="technique in sortTechniques(
                get(problem, 'linkedTechniques'),
              )"
              :key="technique.id"
              class="pill"
            >
              {{ technique.number }} {{ technique.title }}
            </span>
          </div>
        </template>

        <div v-else class="row"><p>Nog geen problematiek gekozen</p></div>
      </tab-pane>

      <tab-pane label="Leerlingen">
        <div class="row">
          <p v-for="student in students" :key="student.id">
            {{ student.fullName }}
          </p>
        </div>
      </tab-pane>

      <tab-pane label="Transferbewustzijn">
        <div class="row">
          <sb-title size="xxsmall"> Waar moet je op letten: </sb-title>
          <p>
            Laat de leerling vertellen waar je bij het lezen van een stukje op
            moet letten. Aanvankelijk moet je dat misschien voorzeggen, maar op
            den duur moet het kind zelf in staat zijn weer te geven waar het aan
            moet denken, als het de leesvaardigheid toe moet passen. Dit kun je
            toevoegen aan het cognitieblad.
          </p>
        </div>
      </tab-pane>
    </tabs>
  </sb-cards-session-info-modal>
</template>

<script>
import gql from 'graphql-tag';
import SbCardsSessionInfoModal from './SbCardsSessionInfoModal.vue';
import { problemTypeMixin } from '@/lib/problem-type';
import { sortTechniquesMixin } from '@/lib/sort-techniques';

export default {
  components: { SbCardsSessionInfoModal },

  mixins: [problemTypeMixin, sortTechniquesMixin],

  props: {
    students: { type: Array, default: () => [] },
    problemId: { type: String, default: undefined },
    cardId: { type: String, default: undefined },
  },

  inject: ['getParentView'],

  apollo: {
    card: {
      skip() {
        return !this.cardId;
      },

      update: (data) => data.getCardById,

      query() {
        return gql`
          query SessionModal_GetCard {
            getCardById(id: "${this.cardId}") {
              id
              title
              number
              instructionTitle
              instruction
              type
              level
              methodologies
            }
          }
        `;
      },
    },

    problem: {
      skip() {
        return !this.problemId;
      },

      update: (data) => data.getProblemById,

      query() {
        return gql`
          query SessionModal_GetCard {
            getProblemById(id: "${this.problemId}") {
              id
              title
              description
              types
              linkedTechniques {
                id
                number
                title
              }
            }
          }
        `;
      },
    },
  },
};
</script>

<style lang="scss">
.sb_session-modal_tabs {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ivu-tabs-content {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
  }
}
</style>

<style lang="scss" scoped>
.container {
  flex: 1;
  min-height: 0;
  overflow: auto;

  .sb_divider::v-deep {
    margin: 0 !important;
    position: sticky;
    top: 0;
    background-image: linear-gradient(
      to bottom,
      $brand-white 50%,
      rgba($brand-white, 0) 100%
    );
  }
}

.row {
  margin: 1rem 0;
}

.section {
  padding: 2rem 0;
  &:nth-child(even) {
    /* background: rgba($brand-primary-lighter, 1); */
  }
}

.pill {
  display: inline-block;
  border-radius: 6px;
  background: $brand-primary-lighter;
  font-size: 0.9rem;
  padding: 0.2em 0.5em;
  color: $brand-white;
  margin-right: 0.5em;
}
</style>
