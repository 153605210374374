var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sb-cards-session-info-modal',_vm._g(_vm._b({},'sb-cards-session-info-modal',_vm.$attrs,false),_vm.$listeners),[_c('sb-title',[_vm._v(" Sessie ")]),_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" In dit venster vind je informatie over de huidige sessie. ")]),_c('tabs',{staticClass:"sb_session-modal_tabs",attrs:{"animated":false}},[_c('tab-pane',{attrs:{"label":"Kaart"}},[(_vm.card)?[_c('div',{staticClass:"row"},[_c('p',[_vm._v("#"+_vm._s(_vm.get(_vm.card, 'number')))]),_c('sb-title',{attrs:{"size":"xsmall"}},[_vm._v(" "+_vm._s(_vm.get(_vm.card, 'title'))+" ")]),_c('p',[_c('b',{staticStyle:{"margin-right":"0.5em"}},[_vm._v(_vm._s(_vm.get(_vm.card, 'level')))]),_c('span',[_vm._v(_vm._s(_vm.get(_vm.card, 'type')))])])],1),_c('div',{staticClass:"row"},[(
              _vm.get(
                _vm.getParentView().cardPracticeAmountLookup,
                _vm.get(_vm.card, 'id'),
                'count'
              )
            )?_c('p',[_vm._v(" Aantal keer geoefend: "+_vm._s(_vm.get( _vm.getParentView().cardPracticeAmountLookup, _vm.get(_vm.card, 'id'), 'count' ))+" ")]):_c('p',[_vm._v("Nog niet geoefend")])]),_c('div',{staticClass:"row"},[_c('sb-title',{staticStyle:{"margin-top":"2rem"},attrs:{"size":"xxsmall"}},[_vm._v(" Methodes ")]),_c('div',_vm._l((_vm.get(_vm.card, 'methodologies')),function(method){return _c('span',{key:method,staticClass:"pill"},[_vm._v(" "+_vm._s(method)+" ")])}),0)],1)]:_c('div',{staticClass:"row"},[_c('p',[_vm._v("Nog geen kaart gekozen")])])],2),_c('tab-pane',{attrs:{"label":"Problematiek"}},[(_vm.problem)?[_c('div',{staticClass:"row"},[_c('sb-title',{attrs:{"size":"xsmall"}},[_vm._v(" "+_vm._s(_vm.get(_vm.problem, 'title'))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.get(_vm.problem, 'description'))}})],1),_c('div',{staticClass:"row"},[_c('sb-title',{attrs:{"size":"xxsmall"}},[_vm._v(" Probleemtypes ")]),_vm._l((_vm.get(_vm.problem, 'types')),function(type){return _c('span',{key:type,staticClass:"pill"},[_vm._v(" "+_vm._s(_vm.getProblemTypeLabel(type, true))+" ")])})],2),_c('div',{staticClass:"row"},[_c('sb-title',{attrs:{"size":"xxsmall"}},[_vm._v(" Technieken ")]),_vm._l((_vm.sortTechniques(
              _vm.get(_vm.problem, 'linkedTechniques')
            )),function(technique){return _c('span',{key:technique.id,staticClass:"pill"},[_vm._v(" "+_vm._s(technique.number)+" "+_vm._s(technique.title)+" ")])})],2)]:_c('div',{staticClass:"row"},[_c('p',[_vm._v("Nog geen problematiek gekozen")])])],2),_c('tab-pane',{attrs:{"label":"Leerlingen"}},[_c('div',{staticClass:"row"},_vm._l((_vm.students),function(student){return _c('p',{key:student.id},[_vm._v(" "+_vm._s(student.fullName)+" ")])}),0)]),_c('tab-pane',{attrs:{"label":"Transferbewustzijn"}},[_c('div',{staticClass:"row"},[_c('sb-title',{attrs:{"size":"xxsmall"}},[_vm._v(" Waar moet je op letten: ")]),_c('p',[_vm._v(" Laat de leerling vertellen waar je bij het lezen van een stukje op moet letten. Aanvankelijk moet je dat misschien voorzeggen, maar op den duur moet het kind zelf in staat zijn weer te geven waar het aan moet denken, als het de leesvaardigheid toe moet passen. Dit kun je toevoegen aan het cognitieblad. ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }