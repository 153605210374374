<template>
  <sb-cards-session-info-modal v-bind="$attrs" v-on="$listeners">
    <tabs v-if="studentIds.length > 1">
      <tab-pane
        v-for="(studentId, index) in studentIds"
        :key="studentId"
        :label="get($refs, 'diagnoses', index, 'student', 'fullName')"
      >
        <sb-spacer height="20" />
        <sb-student-diagnose ref="diagnoses" :student-id="studentId" />
      </tab-pane>
    </tabs>

    <template v-if="studentIds.length === 1">
      <sb-title
        size="small"
        :text="get($refs, 'diagnosis', 'student', 'fullName')"
      />
      <sb-student-diagnose ref="diagnosis" :student-id="studentIds[0]" />
    </template>
  </sb-cards-session-info-modal>
</template>

<script>
import SbStudentDiagnose from '@/components/SbStudentDiagnose.vue';
import SbCardsSessionInfoModal from './SbCardsSessionInfoModal.vue';

export default {
  name: 'SbDiagnoseModal',
  components: { SbCardsSessionInfoModal, SbStudentDiagnose },
  props: { studentIds: { type: Array, required: true } },

  computed: {},
};
</script>
