<template>
  <modal
    :value="value"
    width="80vw"
    class-name="sb_cards-session-info-modal"
    footer-hide
    @input="$emit('input', $event)"
  >
    <slot />
  </modal>
</template>

<script>
export default {
  name: 'SbCardsSessionInfoModal',
  props: {
    value: { type: Boolean },
  },
};
</script>

<style lang="scss">
.sb_cards-session-info-modal {
  .ivu-modal-content {
    height: 80vh;

    .ivu-modal-body {
      overflow: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
