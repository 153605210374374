var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sb_cards-session-focus"},[_c('modal',{attrs:{"footer-hide":"","title":_vm.get(_vm.activeProblemInfo, 'title'),"width":800},on:{"close":function($event){_vm.activeProblemInfo = undefined}},model:{value:(_vm.problemInfoModal),callback:function ($$v) {_vm.problemInfoModal=$$v},expression:"problemInfoModal"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.get(_vm.activeProblemInfo, 'description'))}})]),(
      _vm.getParentView &&
      _vm.get(_vm.getParentView(), '$apollo', 'queries', 'getUserById', 'loading')
    )?_c('sb-loading'):_vm._e(),(_vm.students.length > 0)?[_c('div',{staticClass:"sb_cards-session-focus_inner"},[_c('sb-cards-session-header',{attrs:{"title":_vm.title,"description":_vm.description}},[_c('div',{staticClass:"tag-container"},_vm._l((_vm.tags),function(tag){return _c('div',{staticClass:"tag"},[_vm._v(" "+_vm._s(tag)+" ")])}),0)]),(_vm.accumulatedProblemsAmount > 0 && _vm.students.length > 0)?[_c('sb-list-head',{staticStyle:{"grid-template-columns":"1fr 250px 300px"}},[_c('sb-list-head-col',[_vm._v(" Taken ")]),_c('sb-list-head-col',[_vm._v(" Aantal x geoefend ")]),_c('sb-list-head-col',[_vm._v(" Technieken ")])],1),_vm._l((_vm.accumulatedProblems),function(entry,key,index){return _c('sb-list-row',{key:key,staticClass:"sb_cards-session-focus_problem-type-row",style:({
            gridTemplateColumns: '50px 40px 1fr 250px 300px',
            zIndex: Object.keys(_vm.accumulatedProblems).length - index,
            position: 'relative',
          }),attrs:{"data-id":key},nativeOn:{"click":function($event){return _vm.handleListRowClick.apply(null, arguments)}}},[_c('sb-list-row-col',{staticStyle:{"height":"100%","padding":"0"},nativeOn:{"click":function($event){$event.stopPropagation();_vm.showProblemInfoModal(_vm.get(entry, 'problem', 'problem'))}}},[_c('div',{staticClass:"info"},[_c('sb-icon',{attrs:{"icon-id":"icon_info"}})],1)]),_c('sb-list-row-col',[_c('radio',{attrs:{"size":"large","value":_vm.problemId === key},on:{"input":function($event){$event ? (_vm.problemId = key) : false}}})],1),_c('sb-list-row-col',[_c('div',[_vm._v(_vm._s(_vm.get(entry, 'problem', 'problem', 'title')))])]),_c('sb-list-row-col',[_vm._v(" "+_vm._s(entry.sessionsAmount)+" "),_c('span',{staticStyle:{"width":"30px","display":"inline-block"}}),_vm._v(" "+_vm._s(entry.sessionsAmount && entry.lastSessionDate ? new Date(entry.lastSessionDate).toLocaleDateString() : '-')+" ")]),_c('sb-list-row-col',[_c('sb-problems-input-techniques',{attrs:{"items":_vm.linkedTechniquesFromProblem(entry.problem)},on:{"click-technique":_vm.handleTechniqueClick}})],1)],1)})]:[_c('div',{staticClass:"sb_cards-session-focus_inner"},[_c('sb-call-out',[_c('template',{slot:"left"},[_vm._v(" ℹ️ ")]),_c('template',{slot:"right"},[_c('p',[_vm._v(" "+_vm._s(_vm.studentTracksWithoutProblems.length === 1 ? 'Deze leerling heeft een actief traject zonder gedefinieerde problematieken.' : ("De volgende leerlingen hebben een actief traject zonder problematieken: " + (_vm.studentTracksWithoutProblems .map(function (ref) {
              var student = ref.student;

              return student.fullName;
}) .join(', ')) + "."))+" ")]),_c('p',[_vm._v(" Kies een focuspunt voor deze sessie uit onderstaande taken. ")])])],2)],1)],_c('sb-spacer',{attrs:{"height":"40"}}),(_vm.showCommonProblems)?[(_vm.studentTracks.length !== _vm.studentTracksWithoutProblems.length)?_c('div',{staticClass:"sb_common-problems-title"},[_vm._v(" Overige focuspunten ")]):_vm._e(),_c('sb-list-head',{staticStyle:{"grid-template-columns":"1fr 250px 300px"}},[_c('sb-list-head-col',[_vm._v(" Taken ")]),_c('sb-list-head-col',[_vm._v(" Aantal x geoefend ")]),_c('sb-list-head-col',[_vm._v(" Technieken ")])],1),_vm._l((_vm.commonProblems),function(problem,key,index){return _c('sb-list-row',{key:key,staticClass:"sb_cards-session-focus_problem-type-row",style:({
            gridTemplateColumns: '50px 40px 1fr 250px 300px',
            zIndex: Object.keys(_vm.commonProblems).length - index,
            position: 'relative',
          }),attrs:{"data-id":key},nativeOn:{"click":function($event){return _vm.handleListRowClick.apply(null, arguments)}}},[_c('sb-list-row-col',{staticStyle:{"height":"100%","padding":"0"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.showProblemInfoModal(problem)}}},[_c('div',{staticClass:"info"},[_c('sb-icon',{attrs:{"icon-id":"icon_info"}})],1)]),_c('sb-list-row-col',[_c('radio',{attrs:{"size":"large","value":_vm.problemId === key},on:{"input":function($event){$event ? (_vm.problemId = key) : false}}})],1),_c('sb-list-row-col',[_c('div',[_vm._v(_vm._s(problem.title))])]),_c('sb-list-row-col',[_vm._v(" "+_vm._s(problem.sessionsAmount)+" "),_c('span',{staticStyle:{"width":"30px","display":"inline-block"}}),_vm._v(" "+_vm._s(problem.sessionsAmount && problem.lastSessionDate ? new Date(problem.lastSessionDate).toLocaleDateString() : '')+" ")]),_c('sb-list-row-col',[_c('sb-problems-input-techniques',{attrs:{"items":problem.linkedTechniques || []},on:{"click-technique":_vm.handleTechniqueClick}})],1)],1)})]:_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('i-button',{attrs:{"size":"large","type":"text"},on:{"click":function($event){_vm.showCommonProblems = true}}},[_vm._v(" Bekijk overige focuspunten ")])],1),_c('sb-spacer',{attrs:{"height":"60"}})],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }