<template>
  <div class="sb_cards-session_application">
    <div class="sb_cards-session_application_inner">
      <sb-title text="Hoe gaan we dit toepassen?" size="large" />

      <div style="margin-bottom: 2rem">
        <div
          v-for="(factor, index) in AWARENESS_FACTORS"
          :key="index"
          class="sb_cards-session-transfer-awareness_row"
        >
          {{ factor }}
        </div>
      </div>

      <tabs v-if="get(students, 'length')" :animated="false">
        <tab-pane
          v-for="student in students"
          :key="student.id"
          :label="student.fullName"
        >
          <div class="sb_cards-session_application_tiles">
            <div
              v-for="(tile, index) in AppliedType"
              :key="index"
              class="sb_cards-session_application_tiles_tile"
              :class="{
                s_active: getState().entries[student.id].value.includes(tile),
              }"
              @click="toggleEntry(student.id, tile)"
            >
              {{ tile }}
            </div>
          </div>

          <div class="sb_cards-session_application_explanation">
            <p>
              <checkbox
                :value="getState().custom"
                @input="
                  getState().entries[student.id].value = [];
                  getState().custom = $event;
                "
              >
                Anders, namelijk:
              </checkbox>
            </p>
            <i-input
              v-if="getState().custom"
              size="large"
              placeholder="Welk boek of krant wordt gebruikt?"
              :value="getState().entries[student.id].value.join(', ')"
              @input="getState().entries[student.id].value = [$event]"
            />
          </div>
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import { appliedTypeMixin } from '@/lib/applied-type';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import gql from 'graphql-tag';
import Vue from 'vue';

const CACHE_KEY = 'appliedType';
const AWARENESS_FACTORS = [
  'Dit betreft praktijklezen. Laat de leerling vertellen waar je bij het lezen van een stukje op moet letten. Eerst moet je dat misschien voorzeggen. Maar op den duur moet het kind zelf in staat zijn weer te geven waar het aan moet denken als het de leesvaardigheid toe moet passen. Dit kun je toevoegen aan het cognitieblad.',
];

export default {
  name: 'SbCardsSessionApplication',
  mixins: [
    appliedTypeMixin,
    nonReactiveMembersMixin(() => ({ AWARENESS_FACTORS })),
  ],
  inject: ['students', 'sessionState'],

  computed: {
    sessionIds() {
      return this.$route.query.sessionIds?.split(',') || [];
    },
    studentSessionLookup() {
      return (
        this.students?.reduce(
          (acc, student, index) => (
            (acc[student.id] = this.sessionIds[index]), acc
          ),
          {},
        ) || {}
      );
    },
  },
  created() {
    const state = this.getState();
    const studentIds = this.$route.query.studentIds?.split(',') ?? [];
    if (!state) {
      this.sessionState[CACHE_KEY] = Vue.observable({
        entries: studentIds.reduce(
          (acc, studentId) => (
            (acc[studentId] = { dirty: false, value: [] }), acc
          ),
          {},
        ),
        custom: false,
      });
    } else {
      Object.values(state.entries).forEach((entry) => (entry.dirty = false));
    }
    Object.keys(this.getState().entries).forEach((studentId) => {
      this.$watch(
        ['sessionState', CACHE_KEY, 'entries', studentId].join('.'),
        (entry) => (entry.dirty = true),
        { deep: true },
      );
    });
  },
  methods: {
    getState() {
      return this.sessionState[CACHE_KEY];
    },
    toggleEntry(studentId, value) {
      const state = this.getState().entries[studentId];
      const entries = state.value;

      const customOptionActive = this.getState().custom;
      if (customOptionActive) {
        // Turn custom option off
        entries.splice(0, entries.length);
        entries.push(value);
        this.getState().custom = false;
        return;
      } else if (entries.includes(value)) {
        state.value = state.value.filter((v) => v !== value);
      } else entries.push(value);
    },
    async submitAwareness() {
      try {
        await Promise.all(
          this.students.map(async (student) => {
            const sessionId = this.studentSessionLookup[student.id];
            const appliedType = this.getState().entries[student.id];
            if (!appliedType.dirty) return;
            this.$apollo.mutate({
              mutation: gql`
                mutation UpdateSession($input: UpdateSessionInput!) {
                  updateSession(input: $input) {
                    id
                    appliedType
                  }
                }
              `,
              variables: {
                input: {
                  id: sessionId,
                  data: { appliedType: appliedType.value.join(', ') },
                },
              },
            });
          }),
        );
        this.$Message.success('Toepassing opgeslagen');
      } catch (error) {
        console.error(error);
        this.$Message.error('Er ging iets mis');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_cards-session_application {
  overflow: auto;
  padding: 2rem 0;

  &_inner {
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .ivu-tabs {
      min-height: 380px;
    }
  }

  &_tiles {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 96px;
    gap: 20px;

    &_tile {
      border-radius: $default-border-radius;
      border: 2px solid $brand-task-apply;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      color: $brand-task-apply;
      font-size: 20px;
      cursor: pointer;
      transition: 0.3s background-color;

      &:hover {
        background-color: rgba($brand-task-apply, 0.2);
        transition-duration: 0s;
      }

      &.s_active {
        color: $brand-white;
        background-color: $brand-task-apply;
        &:hover {
          background-color: $brand-task-apply;
        }
      }
    }
  }

  &_explanation {
    & .ivu-checkbox-wrapper {
      display: inline-block !important;
      margin-left: 5px;
    }
  }
}
</style>
