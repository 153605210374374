<template>
  <sb-cards-session-info-modal v-bind="$attrs" v-on="$listeners">
    <sb-techniques-list :sync-with-query-param="syncWithQueryParam" />
  </sb-cards-session-info-modal>
</template>

<script>
import SbTechniquesList from '@/components/SbTechniquesList.vue';
import SbCardsSessionInfoModal from './SbCardsSessionInfoModal.vue';
export default {
  name: 'SbTechniquesModal',
  components: { SbTechniquesList, SbCardsSessionInfoModal },

  props: {
    syncWithQueryParam: { type: Boolean, default: true },
  },
};
</script>
