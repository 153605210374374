<template>
  <div>
    <sb-title text="Diagnose-kaart" />

    <sb-divider top-space="40px" title="Niveau" />

    <div class="sb_student-diagnose_info">
      <div>Niveau</div>
      <div>{{ level }}</div>
    </div>

    <sb-divider top-space="60px" title="DMT Toetsresultaten" />

    <sb-dmt-test-result-input
      v-if="!!intakeDmtTest"
      :value="intakeDmtTest.results"
      class="sb_student-diagnose_test-results"
      readonly
    />

    <sb-divider top-space="60px" title="Focuspunten" />

    <sb-problems-input
      v-if="!!problemEntries"
      :value="problemEntries"
      class="sb_student-diagnose_test-results"
      readonly
    />

    <template v-if="!!intakeDmtTest">
      <sb-divider top-space="60px" title="Afname van toets" />

      <div class="sb_student-diagnose_info">
        <div>Datum</div>
        <div v-if="intakeDmtTest.createdAt">
          {{ new Date(intakeDmtTest.createdAt).toLocaleDateString() }}
        </div>
      </div>

      <div class="sb_student-diagnose_info">
        <div>Afgenomen door</div>
        <div>{{ intakeDmtTest.testSupervisor }}</div>
      </div>

      <sb-divider top-space="60px" title="Beoordelingen & opmerkingen" />

      <p class="sb_student-diagnose_remark">{{ intakeDmtTest.remark }}</p>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SbTitle from './global/SbTitle.vue';
import SbDmtTestResultInput from './SbDmtTestResultInput.vue';
import SbProblemsInput from '@/components/SbProblemsInput.vue';
export default {
  name: 'SbStudentDiagnose',

  components: { SbTitle, SbDmtTestResultInput, SbProblemsInput },

  props: { studentId: { type: String, required: true } },

  computed: {
    student() {
      return this.diagnose?.student;
    },

    intakeDmtTest() {
      return this.diagnose?.intakeDmtTest;
    },

    level() {
      return this.diagnose?.readingLevel;
    },

    problemEntries() {
      return this.diagnose?.problems;
    },
  },

  apollo: {
    diagnose: {
      variables() {
        return {
          selfId: this.$user.id,
          studentFilter: { id: { equals: this.studentId } },
          trackFilter: { active: true },
        };
      },

      query: gql`
        query StudentDiagnose(
          $selfId: ID!
          $studentFilter: UsersFilter
          $trackFilter: TracksFilter
        ) {
          getUserById(id: $selfId) {
            id
            coachingStudents(filter: $studentFilter, first: 1) {
              edges {
                node {
                  id
                  fullName
                  tracks(filter: $trackFilter, first: 1) {
                    edges {
                      node {
                        id
                        readingLevels
                        intakeDmtTests {
                          id
                          createdAt
                          levels
                          testSupervisor
                          remark
                          results {
                            id
                            type
                            amountOfWords
                            amountOfErrors
                          }
                        }
                        problems {
                          id
                          mistakeAmount
                          order
                          problemId
                          problem {
                            id
                            title
                            description
                            types
                            linkedTechniques {
                              id
                              title
                              number
                              content
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,

      update(response) {
        const {
          coachingStudents: {
            edges: [{ node: student }],
          },
        } = response.getUserById;

        if (!student) throw new Error('Could not find student!');

        const {
          tracks: {
            edges: [{ node: track }],
          },
        } = student;

        if (!track) throw new Error('Could not find track for student!');

        const { intakeDmtTests, readingLevel, problems } = track;

        const intakeDmtTest = intakeDmtTests.reduce((latest, current) => {
          if (!latest || current.createdAt > latest.createdAt) return current;
        }, undefined);

        return {
          student,
          intakeDmtTest,
          readingLevel,
          problems: problems.filter((problem) => !!problem.problem),
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_student-diagnose {
  &_info {
    display: grid;
    grid-template-columns: 180px auto;
    margin: 20px 0;
  }

  &_info,
  &_remark {
    padding-left: 25px;
  }

  &_test-results {
    margin-left: 15px;
    margin-top: -20px;
  }
}
</style>
