var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sb_session_steps-indicator"},[_c('button',{staticClass:"stop-button",on:{"click":function($event){return _vm.$router.back()}}},[_c('sb-button',{attrs:{"naked":"","icon-animation-direction":"left","button-type":"red","size":"small","icon-size":"regular"}},[_c('sb-icon',{attrs:{"icon-id":"icon_arrow-left"}}),_vm._v(" Stop ")],1)],1),_c('div',{staticClass:"sb_session_steps-indicator_container"},[_c('div',{staticClass:"sb_session_steps-indicator_inner"},[_c('div',{staticClass:"sb_session_steps-indicator_line",style:({
          width: 100 / _vm.activeStepsAmount + '%',
          left: (100 / _vm.activeStepsAmount) * (_vm.currentStep - 1) + '%',
        }),attrs:{"current-task":_vm.activeSteps[_vm.currentStep].task,"current-step-index":_vm.currentStep}}),_c('div',{staticClass:"sb_session_steps-indicator_steps"},_vm._l((_vm.activeSteps),function(step,i){return _c('div',{key:("step-" + i),staticClass:"sb_session_steps-indicator_step",class:{
            s_current: i === _vm.currentStep,
            s_active: i <= _vm.currentStep,
          },attrs:{"task":step.task}},[_c('span',{class:{ s_after_text: true },attrs:{"task":step.task}}),_c('button',{staticClass:"sb_session_steps-indicator_step_text",class:{
              s_enabled: step.enabled,
            },attrs:{"data-enabled":step.enabled},on:{"click":function($event){return _vm.$emit('click-step', step)}}},[_vm._v(" "+_vm._s(step.title)+" ")])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }