<template>
  <div class="sb_session_steps-indicator">
    <button class="stop-button" @click="$router.back()">
      <sb-button
        naked
        icon-animation-direction="left"
        button-type="red"
        size="small"
        icon-size="regular"
      >
        <sb-icon icon-id="icon_arrow-left" />
        Stop
      </sb-button>
    </button>
    <div class="sb_session_steps-indicator_container">
      <div class="sb_session_steps-indicator_inner">
        <div
          class="sb_session_steps-indicator_line"
          :current-task="activeSteps[currentStep].task"
          :current-step-index="currentStep"
          :style="{
            width: 100 / activeStepsAmount + '%',
            left: (100 / activeStepsAmount) * (currentStep - 1) + '%',
          }"
        />
        <div class="sb_session_steps-indicator_steps">
          <div
            v-for="(step, i) in activeSteps"
            :key="`step-${i}`"
            class="sb_session_steps-indicator_step"
            :task="step.task"
            :class="{
              s_current: i === currentStep,
              s_active: i <= currentStep,
            }"
          >
            <span :class="{ s_after_text: true }" :task="step.task" />
            <button
              class="sb_session_steps-indicator_step_text"
              :class="{
                s_enabled: step.enabled,
              }"
              :data-enabled="step.enabled"
              @click="$emit('click-step', step)"
            >
              {{ step.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    activeSteps() {
      return this.steps.filter((step) => step.showInIndicator);
    },
    activeStepsAmount() {
      return this.activeSteps.length - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_session_steps-indicator {
  height: 54px;
  background: $brand-lightest-gray;
  display: flex;
  /* height: 50px; */

  @media screen and (max-height: $small-height-screen) {
    height: 40px;
  }

  .stop-button {
    padding: 0 0.6rem;
    border-right: 1px solid $brand-light-gray;

    @media screen and (min-height: $small-height-screen) {
      .sb_button {
        .sb_button_wrapper {
          font-size: 12px;
          color: red;
        }
      }
    }
  }

  //
  // CONTAINER
  //
  &_container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 18px 3rem 0 3rem;
    flex: 1;
    /* overflow: hidden; */
  }

  //
  //  INNER
  //
  &_inner {
    position: relative;
    //
    // FAUX LINE
    //
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 1px;
      left: 0;
      width: 100%;
      height: 8px;
      background: $brand-white;
    }
  }

  //
  // LINE
  //
  &_line {
    position: absolute;
    z-index: 4;
    top: 0.2rem;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background: linear-gradient(90deg, white, $brand-primary);
    transition: width 0.3s ease, left 0.3s ease, opacity 0.3s ease;
    transform-origin: 0 50%;

    &[current-step-index='0'] {
      opacity: 0;
      width: 0% !important;
    }

    //
    //  STEP TASK COLORS
    //
    &[current-task='diagnose'] {
      background: linear-gradient(90deg, white, $brand-task-diagnose);
    }
    &[current-task='cognition'] {
      background: linear-gradient(90deg, white, $brand-task-cognition);
    }
    &[current-task='practise'] {
      background: linear-gradient(90deg, white, $brand-task-practise);
    }
    &[current-task='apply'] {
      background: linear-gradient(90deg, white, $brand-task-apply);
    }
  }

  //
  // STEPS
  //
  &_steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 0 2rem 0;
  }

  //
  // STEP
  //
  &_step {
    position: relative;
    padding: 0.8rem 0 0 0;
    user-select: none;

    //
    //  STEP - DOT
    //
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $brand-lightest-gray;
      box-shadow: 0 0 0 7px white;
      transition: background-color 0.3s ease 0.3s;
    }
    //
    //  STEP - DOT - OVER
    //
    &:after {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $brand-lightest-gray;
      transition: background-color 0.3s ease 0.3s;
    }

    //
    //  STEP TASK COLORS
    //
    &[task='diagnose'] {
      &:before,
      &:after {
        background: $brand-task-diagnose;
      }
    }
    &[task='cognition'] {
      &:before,
      &:after {
        background: $brand-task-cognition;
      }
    }
    &[task='practise'] {
      &:before,
      &:after {
        background: $brand-task-practise;
      }
    }
    &[task='apply'] {
      &:before,
      &:after {
        background: $brand-task-apply;
      }
    }

    //
    // STEP - TEXT
    //
    &_text {
      position: absolute;
      transform: translateX(-50%);
      white-space: nowrap;
      font-size: 0.8rem;
      color: $brand-gray;
      transition: color 0.3s ease 0.3s;
      display: none;
      pointer-events: none;

      @include breakpoint($medium) {
        display: block;
      }

      @media screen and (max-height: $small-height-screen) {
        display: none;
      }

      &.s_enabled {
        pointer-events: unset;
        color: $brand-black;
        &:hover {
          cursor: pointer;
        }
      }
    }

    //
    // STATE - ACTIVE
    //
    &.s_active {
      .sb_session_steps-indicator_step_text {
        color: $brand-primary;
        pointer-events: inherit;
        font-weight: 600;
      }
    }

    //
    // STATE - CURRENT
    //
    &.s_current {
      .sb_session_steps-indicator_step_text {
        display: block;
        color: $brand-black;
        pointer-events: none;

        @media screen and (max-height: $small-height-screen) {
          display: none;
        }
      }
    }
  }
}

.s_after_text {
  position: absolute;
  inset: 0;

  @media screen and (min-height: $small-height-screen) {
    display: none;
  }

  &[task='session'] {
    &::after {
      color: $brand-primary;
      position: absolute;
      content: 'focus';
      width: 100px;
      height: 20px;
      inset: 0;
      z-index: 10;
      top: -7px;
      left: 10px;
      text-transform: capitalize;
      font-size: 14px;
      right: 100px;
      font-size: 10px;
    }
  }

  &[task='diagnose'] {
    &::after {
      color: $brand-task-diagnose;

      position: absolute;
      content: 'focus';
      width: 100px;
      height: 20px;
      inset: 0;
      z-index: 10;
      top: -5px;
      left: 10px;
      text-transform: capitalize;
      font-size: 14px;
    }
  }
  &[task='practise'] {
    &::after {
      color: $brand-task-practise;

      position: absolute;
      content: 'trainen';
      width: 100px;
      height: 20px;
      inset: 0;
      z-index: 10;
      top: -5px;
      left: 10px;
      text-transform: capitalize;
      font-size: 14px;
      right: 100px;
    }
  }

  &[task='apply'] {
    &::after {
      color: $brand-task-apply;

      position: absolute;
      content: 'toepassen';
      width: 100px;
      height: 20px;
      inset: 0;
      z-index: 10;
      top: -5px;
      left: 10px;
      text-transform: capitalize;
      font-size: 14px;
    }
  }

  &[task='cognition'] {
    &::after {
      color: $brand-task-cognition;

      position: absolute;
      content: 'afronden';
      width: 100px;
      height: 20px;
      inset: 0;
      z-index: 10;
      top: -5px;
      left: 10px;
      text-transform: capitalize;
      font-size: 14px;
    }
  }

  &[task='record'] {
    &::after {
      color: $brand-task-record;

      position: absolute;
      content: 'record';
      width: 100px;
      z-index: 10;
      top: -5px;
      left: 10px;
      text-transform: capitalize;
    }
  }
}
</style>
