<template>
  <div class="sb_cards-session_completion">
    <div class="sb_cards-session_completion_inner">
      <sb-spacer height="80" />

      <sb-title text="Afronden" size="large" />

      <sb-spacer height="20" />

      <tabs v-if="get(students, 'length')" :animated="false">
        <tab-pane
          v-for="student in students"
          :key="student.id"
          :label="student.fullName"
        >
          <template v-for="(entry, key) in getState().entries[student.id]">
            <sb-divider :key="`${key}-title`" :title="getEntryLabel(key)" />
            <div :key="key" class="sb_cards-session_completion_entry">
              <p>Opmerking</p>
              <i-input
                v-model="entry.value"
                :placeholder="getEntryPlaceholder(key)"
                :rows="4"
                type="textarea"
              />
            </div>
          </template>
        </tab-pane>
      </tabs>

      <sb-spacer height="80" />
    </div>
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider.vue';
import gql from 'graphql-tag';
import Vue from 'vue';

const CACHE_KEY = 'finishSession';

export default {
  name: 'SbCardsSessionCompletion',
  components: { SbDivider },
  inject: ['getParentView', 'students', 'sessionState'],

  computed: {
    sessionIds() {
      return this.$route.query.sessionIds?.split(',') || [];
    },

    studentIds() {
      return this.$route.query.studentIds?.split(',') || [];
    },

    studentSessionLookup() {
      return (
        this.students?.reduce(
          (acc, student, index) => (
            (acc[student.id] = this.sessionIds[index]), acc
          ),
          {},
        ) || {}
      );
    },
  },

  created() {
    const state = this.getState();

    if (!state) {
      this.sessionState[CACHE_KEY] = Vue.observable({
        entries: this.studentIds.reduce((acc, studentId) => {
          acc[studentId] = {
            howDidItGo: { value: '', dirty: false },
            whatWentWell: { value: '', dirty: false },
            whatWentWrong: { value: '', dirty: false },
            whatNeedsAttention: { value: '', dirty: false },
          };

          return acc;
        }, {}),
      });
    } else {
      Object.values(state.entries).forEach((entry) =>
        Object.values(entry).forEach((input) => (input.dirty = false)),
      );
    }

    Object.entries(this.getState().entries).forEach(([studentId, entry]) => {
      Object.keys(entry).forEach((input) => {
        this.$watch(
          ['sessionState', CACHE_KEY, 'entries', studentId, input].join('.'),
          (input) => (input.dirty = true),
          { deep: true },
        );
      });
    });
  },

  methods: {
    getState() {
      return this.sessionState[CACHE_KEY];
    },

    getEntryLabel(key) {
      switch (key) {
        case 'howDidItGo':
          return 'Hoe ging het?';
        case 'whatWentWell':
          return 'Wat ging er goed?';
        case 'whatWentWrong':
          return 'Wat ging er minder goed?';
        case 'whatNeedsAttention':
          return 'Waar moet je de volgende keer extra aan denken?';
      }
    },

    getEntryPlaceholder(key) {
      switch (key) {
        case 'howDidItGo':
        case 'whatWentWell':
        case 'whatWentWrong':
          return 'Eventuele opmerkingen voor de leerling';
        case 'whatNeedsAttention':
          return 'Omschrijf wat de leerling antwoordt';
      }
    },

    async submitEntries() {
      let trackId;
      try {
        await Promise.all(
          this.sessionIds.map(async (id, index) => {
            const studentId = this.students[index].id;
            const entry = this.getState().entries[studentId];

            const {
              howDidItGo,
              whatWentWell,
              whatWentWrong,
              whatNeedsAttention,
            } = Object.entries(entry).reduce((acc, [key, input]) => {
              if (input.dirty) acc[key] = input.value;
              return acc;
            }, {});

            const { data } = await this.$apollo.mutate({
              mutation: gql`
                mutation FinishSession($input: FinishSessionInput!) {
                  finishSession(input: $input) {
                    id
                    trackId
                    howDidItGo
                    whatWentWell
                    whatWentWrong
                    whatNeedsAttention
                  }
                }
              `,

              variables: {
                input: {
                  id,
                  data: {
                    howDidItGo,
                    whatWentWell,
                    whatWentWrong,
                    whatNeedsAttention,
                  },
                },
              },
            });

            trackId = data.finishSession?.trackId;
          }),
        );

        this.$Message.success('Sessie afgerond');

        if (this.sessionIds.length > 1) {
          this.$router.push({ name: 'ResourceDashboard' });
        } else {
          console.log(this.students[0].id, this.$route.query.trackIds);
          this.$router.push({
            name: 'ResourceManagementStudentsStudentPathOverview',
            params: {
              studentId: this.students[0].id,
              path: trackId,
            },
          });
        }
      } catch (error) {
        console.error(error);
        this.$Message.error('Er ging iets mis');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_cards-session_completion {
  overflow: auto;

  &_inner {
    width: 800px;
    margin: 0 auto;
  }

  &_entry {
    padding-left: 25px;
  }
}
</style>
